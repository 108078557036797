<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="form.put()" v-if="form"></sc-form>
        </div>
        <div class="btn pd">
            <sc-button @click="$router.push('schedule')">View Schedule</sc-button>
        </div>
        <sc-table :table="playersTable" @view="viewPlayer"></sc-table>
    </div>
</template>

<script>
export default {
  name: "edit",
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
      playersTable: this.$stable.createTable({
        name: 'Assigned players',
        sortBy: 'name',
        columns: {
          name: {
            fill: true,
            target: 'attributes.name'
          },
          status: {
            target: 'attributes.active'
          }
        }
      })
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `Edit ${this.resource.name}`,
      url: `${this.resource.api}/${this.$route.params[this.resource.route_param]}`,
      fields: {
        name: '',
        description: '',
        frameset_id: {
          name: 'Frameset',
          component: 'nr-select',
          options: {
            empty: true,
            data: this.fetchResource('framesets?amount=1000')
          }
        },

      },
      success: () => {
        this.$notify.success(`${this.resource.name} updated`)
        this.$router.push(`/app/${this.resource.route}/index`)
      }
    })
    this.form.get()
      .then(res => {
        if (res.data.players) {
          this.playersTable.data = res.data.players.data
        } else {
          this.playersTable.data = [];
        }
        this.playersTable.hasData = true;
        this.playersTable.prefetch();
      });
  },

  methods: {
    fetchResource(resource) {
      return this.$talker.api(resource)
        .then((res) => {
          return res.data.data;
        })
    },
    viewPlayer(player) {
      this.$router.push(`/app/players/${player.id}/edit`)
    }
  }

}
</script>

<style scoped lang="scss">

</style>